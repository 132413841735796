require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/4a82504ac5a365b515498484f6caee02/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4a82504ac5a365b515498484f6caee02'] = async function fn_4a82504ac5a365b515498484f6caee02(tableName, whereClause, propertiesToInclude, propertiesToExclude, propertiesToSortBy, relations) {
var allCustomers, loading, currentBatch, offset;


  loading = true;
  allCustomers = [];
  offset = 0;
  while (loading) {
    currentBatch = (await Backendless.Data.of(tableName).find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setProperties(propertiesToInclude).excludeProperties(propertiesToExclude).setRelated(relations).setSortBy(propertiesToSortBy).setPageSize(100).setOffset(offset)));
    allCustomers = (allCustomers.concat(currentBatch));
    offset = offset + 100;
    if (currentBatch.length < 100) {
      loading = false;
    }
  }

return allCustomers
}

})
define('./functions/ce6a7744410fa122d5ca861572a6cbf6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ce6a7744410fa122d5ca861572a6cbf6'] = async function fn_ce6a7744410fa122d5ca861572a6cbf6(tableName, dataModel, whereClause, propertiesToInclude, propertiesToExclude, propertiesToSortBy, relations) {
var loadedData, offset;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function init_offset_var() {
  offset = (getObjectProperty(dataModel, 'offset'));
  if (offset == null) {
    offset = 0;
  }
}

/**
 * Describe this function...
 */
async function load_data() {
  loadedData = (await Backendless.Data.of(tableName).find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setProperties(propertiesToInclude).excludeProperties(propertiesToExclude).setRelated(relations).setSortBy(propertiesToSortBy).setPageSize(100).setOffset(offset)));
}

/**
 * Describe this function...
 */
async function adjust_page_data_state() {
  if (loadedData.length < 100) {
    dataModel['loadingData'] = false;
  } else {
    dataModel['offset'] = (offset + loadedData.length);
  }
}


  await init_offset_var();
  await load_data();
  await adjust_page_data_state();

return loadedData
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/dash1/components/59d10e7e04bdbb961badebfcfcd26aba/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash1/components/page/bundle.js', [], () => ({
  /* content */

  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if (await Backendless.UserService.isValidLogin()) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dash1', undefined);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (await Backendless.UserService.isValidLogin()) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dash1', undefined);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }

  },
  /* handler:onEnter */
  /* content */
}));

define('./pages/dash1/components/9e91d9143b61a069a5aa20ff00897fd1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var var_get_meta_from_bubble;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  var_get_meta_from_bubble = (getObjectProperty((await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})('https://orders.galassos.com/version-test/api/1.1/meta')).setEncoding('utf8').send()), 'get'));
  await Backendless.Data.of((String('ff'))).save( '' );
  (function (message) { alert(message) })(var_get_meta_from_bubble);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if (await Backendless.UserService.isValidLogin()) {
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landing', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/dashboard/components/d31ff7dd6e828fbaf7b8577f781a756d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landing', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/29e8ad99368a8e2f5995e2340cec6c8f/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    
  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/dr1/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(token) {
  	Backendless.UserService.setCurrentUser({ 'user-token': token })
  })((getObjectProperty(___arguments.context.pageData, 'token')));
  await (async function() {
  	const getObjectProperty = (object, propPath) => {
  	  if (typeof propPath !== 'string') {
  	    return undefined;
  	  }
  	
  	  const propsNamesList = propPath.split('.');
  	  let result = object;
  	
  	  for (const propName of propsNamesList) {
  	    if (!result || result[propName] === undefined) {
  	      return undefined;
  	    }
  	    result = result[propName];
  	  }
  	
  	  return result;
  	};
  	
  	const loadPageObjects = async (table, pageSize, offset) => {
  	  const whereClause = "Modified_Date IS NOT NULL AND Modified_Date <> ''";
  	  const queryBuilder = Backendless.DataQueryBuilder.create()
  	    .setWhereClause(whereClause)
  	    .setPageSize(pageSize)
  	    .setOffset(offset);
  	
  	  try {
  	    const pageObjects = await Backendless.Data.of(table).find(queryBuilder);
  	    return pageObjects;
  	  } catch (error) {
  	    console.error('Error while loading data:', error.message);
  	    throw new Error('An error occurred while loading data.');
  	  }
  	};
  	
  	// Pagination control
  	const onPageChange = async (newPage) => {
  	  // Update current page
  	  currentPage = newPage;
  	  offset = currentPage * pageSize;
  	
  	  // Load data for the new page
  	  const pageData = await loadPageObjects(table, pageSize, offset);
  	  ___arguments.context.pageData['data'] = pageData;
  	
  	  // Update the UI with the new page data
  	  // You might need to refresh the data grid or the component that displays the data
  	};
  	
  	(async () => {
  	  try {
  	    const token = getObjectProperty(___arguments.context.pageData, 'token');
  	    await Backendless.UserService.setCurrentUser({ 'user-token': token });
  	
  	    const table = getObjectProperty(___arguments.context.pageData, 'table');
  	    
  	    // Get column descriptions and filter out unwanted columns
  	    const columnDescriptions = await Backendless.Data.describe(table);
  	    const filteredColumnDescriptions = columnDescriptions.filter(item => {
  	      const columnName = getObjectProperty(item, 'name');
  	      return columnName !== 'created' && columnName !== 'updated' && columnName !== 'ownerId' && columnName !== 'objectId';
  	    });
  	    
  	    const columns = await Promise.all(filteredColumnDescriptions.map(async (item) => {
  	      const columnName = getObjectProperty(item, 'name');
  	      return { 'field': columnName, 'flex': 1 };
  	    }));
  	
  	    ___arguments.context.pageData['columns'] = columns;
  	
  	    // Pagination parameters
  	    const pageSize = 100; // Number of records per page
  	    let currentPage = 0; // Current page number (0-indexed)
  	    let offset = currentPage * pageSize; // Offset for the current page
  	
  	    // Load initial data
  	    const pageData = await loadPageObjects(table, pageSize, offset);
  	    ___arguments.context.pageData['data'] = pageData;
  	
  	    // Here you would set up your UI to handle pagination
  	    // For example, setting up "Next" and "Previous" buttons to call onPageChange with the correct page number
  	    // nextPageButton.onclick = () => onPageChange(currentPage + 1);
  	    // previousPageButton.onclick = () => onPageChange(currentPage - 1);
  	
  	  } catch (error) {
  	    console.error('An error occurred:', error.message);
  	    throw new Error('An error occurred.');
  	  }
  	})();

  })();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/dr1/components/476210efa19a9d52661eb4d449c645ab/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(rows, fileName) {
  	// Assuming rows is your data array and fileName is the desired name for the CSV file
  	const excludedColumns = ['created', 'updated', 'ownerId', 'objectId','___class']; // Add columns to exclude

  	let headers = Object.keys(rows[0])
  	  .filter(column => !excludedColumns.includes(column))
  	  .join(",") + "\n";

  	let csvContent =
  	  "data:text/csv;charset=utf-8," +
  	  headers +
  	  rows
  	    .map(e =>
  	      Object.keys(e)
  	        .filter(column => !excludedColumns.includes(column))
  	        .map(column => e[column])
  	        .join(",")
  	    )
  	    .join("\n");

  	const encodedUri = encodeURI(csvContent);
  	const link = document.createElement("a");

  	link.setAttribute("href", encodedUri);
  	link.setAttribute("download", `${fileName}.csv`);
  	document.body.appendChild(link);

  	link.click();

  })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('72aec3a55d07ba9c11a90c57d6df8841')), 'rowsData')), ([(getObjectProperty(___arguments.context.pageData, 'table')),'_',((new Date()).toLocaleDateString()),'_',((new Date()).toLocaleTimeString())].join('')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dr1/components/a422aacd789f89c44241d5caf4b2107c/bundle.js', [], () => ({
  /* content */
  /* handler:onGoBack */
  async ['onGoBack'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goPreviousPage() })('a422aacd789f89c44241d5caf4b2107c');

  },
  /* handler:onGoBack */
  /* handler:onGoFirst */
  async ['onGoFirst'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goFirstPage() })('a422aacd789f89c44241d5caf4b2107c');

  },
  /* handler:onGoFirst */
  /* handler:onGoLast */
  async ['onGoLast'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goLastPage() })('a422aacd789f89c44241d5caf4b2107c');

  },
  /* handler:onGoLast */
  /* handler:onGoNext */
  ['onGoNext'](___arguments) {
    
  },
  /* handler:onGoNext */
  /* content */
}))

define('./pages/landing/components/41e388157749f1f7947b999bbaee3241/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.UserService.login( (getObjectProperty(___arguments.context.pageData, 'id_val')), (getObjectProperty(___arguments.context.pageData, 'pass_val')), true  );
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if (await Backendless.UserService.isValidLogin()) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', undefined);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landing', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async onSubmit(___arguments) {
    var user, error, password, username, stayLoggedIn, logging, buttonLogin;

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid){ return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (___arguments.context.dataModel['username']);
  password = (___arguments.context.dataModel['password']);
  stayLoggedIn = (___arguments.context.dataModel['stayLoggedIn']);
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((error['code']) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
  }

  },  
  /* handler:onSubmit *//* content */
}));

define('./pages/login/components/db5546014b7940ad8771a98d85f83036/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.UserService.login( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'username')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'password')), true  );
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dash1', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tdget/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var item, columnstoexclude;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  columnstoexclude = ['objectId', 'ownerId', 'created', 'updated', '_id'];
  ___arguments.context.pageData['columns'] = (await asyncListFilter((await Promise.all((await Backendless.Data.describe( (getObjectProperty(___arguments.context.pageData, 'tasjygwm')) )).map(async item => {; return ({ 'field': (getObjectProperty(item, 'name')),'flex': 1 });}))), async (item) => {


   return (!(columnstoexclude.includes((getObjectProperty(item, 'field')))));
  }));
  ___arguments.context.pageData['data'] = (await Backendless.Data.of((getObjectProperty(___arguments.context.pageData, 'tasjygwm'))).find(Backendless.DataQueryBuilder.create().setPageSize(10)));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/user_setup/components/d0b4d2c1b90b543d816affa145ca37b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'reg_email')) == null) {
    (function (message) { alert(message) })('Email Empty');
  }
  await Backendless.UserService.register( new Backendless.User({ 'email': (getObjectProperty(___arguments.context.pageData, 'reg_email')),'password': (getObjectProperty(___arguments.context.pageData, 'reg_pass')) }) );

  },
  /* handler:onClick */
  /* content */
}))

